/*
 * (c) Copyright 2023 Thalia Bücher GmbH
 *
 * Created on 01.06.2023
 *
 * This file contains unpublished, proprietary trade secret information of
 * Thalia Bücher GmbH. Use, transcription, duplication and
 * modification are strictly prohibited without prior written consent of
 * Thalia Bücher GmbH.
 */

import {BESTANDANZEIGE_CUSTOM_EVENT, BESTANDANZEIGE_REMOVE_CUSTOM_EVENT, CUSTOM_ELEMENT_NAME} from "./config";

class BuchhandlungBestandanzeige extends HTMLElement {
    constructor() {
        super();
        this.render();
        this.boundedAnzeigeUpdateCallback = this.anzeigeUpdateCallback.bind(this);
        this.boundedAnzeigeRemoveCallback = this.anzeigeRemoveCallback.bind(this);
        document.addEventListener(BESTANDANZEIGE_CUSTOM_EVENT, this.boundedAnzeigeUpdateCallback);
        document.addEventListener(BESTANDANZEIGE_REMOVE_CUSTOM_EVENT, this.boundedAnzeigeRemoveCallback);
    }

    set status(wert) {
        this.dataset["status"] = wert;
    }

    get matnr() {
        return this.dataset["matnr"];
    }

    get template() {
        return document.querySelector("script[id=buchhandlung-bestandanzeige]").innerHTML;
    }

    get ampel() {
        return this.querySelector(".bestandanzeige__ampel");
    }

    set ampel(wert) {
        if (!this.ampel) return;
        this.ampel.dataset["status"] = wert;
    }

    get label() {
        return this.querySelector(".bestandanzeige__label");
    }

    set label(wert) {
        if (!this.label) return;
        this.label.innerHTML = wert;
    }

    disconnectedCallback() {
        document.removeEventListener(BESTANDANZEIGE_CUSTOM_EVENT, this.boundedAnzeigeUpdateCallback);
        document.removeEventListener(BESTANDANZEIGE_REMOVE_CUSTOM_EVENT, this.boundedAnzeigeRemoveCallback);
    }

    render() {
        if (!this.template) return;
        this.innerHTML = this.template;
    }

    remove() {
        this.parentNode.removeChild(this);
    }

    setAmpel(hatBestand) {
        if (hatBestand) {
            this.ampel = "gruen";
        } else {
            this.ampel = "rot";
        }
    }

    anzeigeUpdateCallback(event) {
        const {ampelListe} = event.detail;
        // Wenn im Event keine Information zu dieser Matnr vorhanden ist, dann ignorieren
        if (!ampelListe.hasOwnProperty(this.matnr)) return;

        const hatBestand = ampelListe[this.matnr] === 1;
        this.setAmpel(hatBestand);
    }

    anzeigeRemoveCallback(event) {
        this.remove();
    }
}

if (!customElements.get(CUSTOM_ELEMENT_NAME)) {
    customElements.define(CUSTOM_ELEMENT_NAME, BuchhandlungBestandanzeige);
}
