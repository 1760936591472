/*
 * (c) Copyright 2023 Thalia Bücher GmbH
 *
 * Created on 16.05.2023
 *
 * This file contains unpublished, proprietary trade secret information of
 * Thalia Bücher GmbH. Use, transcription, duplication and
 * modification are strictly prohibited without prior written consent of
 * Thalia Bücher GmbH.
 */

export const BESTANDANZEIGE_CUSTOM_EVENT = "buchhandlung-bestandanzeige-status";
export const BESTANDANZEIGE_REMOVE_CUSTOM_EVENT = "buchhandlung-bestandanzeige-remove";
export const CUSTOM_ELEMENT_NAME = "buchhandlung-bestandanzeige";
export const ABHOLFILIALE_COOKIE_NAME = "abholfiliale";
export const SUCHE_ABHOLFILIALE_COOKIE_NAME = "such_abholfiliale";
export const AMPEL_STATUS_SET_ID = "ampel-status-set";
